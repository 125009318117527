export const environment = {
  production: true,
  qa: true,
  endpoints: {
    accounts: 'https://myriad-accounts-api-qa-as.azurewebsites.net',
    catalog: 'https://myriad-catalog-api-qa-as.azurewebsites.net',
    crm: 'https://myriad-crm-api-qa-as.azurewebsites.net',
    identity: 'https://myriad-identity-api-qa-as.azurewebsites.net',
    inventory: 'https://myriad-inventory-api-qa-as.azurewebsites.net',
    merchandise: 'https://myriad-merchandise-api-qa-as.azurewebsites.net',
    monolith: 'https://myriad-monolith-api-qa-as.azurewebsites.net',
    reporting: 'https://myriad-reporting-api-qa-as.azurewebsites.net/api',
    sales: 'https://myriad-sales-api-qa-as.azurewebsites.net'
  },
  clientSecret: '609642b3-0eee-4254-9f8f-362cd00f7d9d',
  lsKey: 'pcqa'
};
